export enum PermissionType {
  CNAM = "CNAM",
  History = "History",
  Explore = "Explore",
  Network = "Network",
  PortingProfiles = "PortingProfiles",
  CarrierProfiles = "CarrierProfiles",
  TradingPartners = "TradingPartners",
  Admin = "Admin",
  CompanyAdmin = "CompanyAdmin",
  NetworkWrite = "NetworkWrite",
  SOARead = "SOARead",
  SOAWrite = "SOAWrite",
  Webhooks = "Webhooks",
  Reports = "Reports",
  WorkQueue = "WorkQueue",
  PreOrderFree = "PreOrderFree",
  AllowCsr = "AllowCsr",
  Search = "Search",
  Provision = "Provision",
  CNAMUpdate = "CNAMUpdate",
  RealNumberDNO = "RealNumberDNO",
  CustomerInventory = "CustomerInventory",
  Notifications = "Notifications",
  DisasterRecovery = "DisasterRecovery"
}
