import React, { useState, useMemo, useEffect } from "react";
import { Switch, Redirect, HashRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Login from "./components/account/Login";
import ForgotPassword from "./components/account/ForgotPassword";
import ResetPassword from "components/account/ResetPassword";
import NumberExplorer from "./components/numberExplorer/NumberExplorer";
import NetworkExplorer from "./components/networkExplorer/NetworkExplorer";
import OrdersList from "./components/orders/ordersList/OrderList";
import translations from "./translations";
import MyProfile from "components/myProfile/MyProfile";
import SupportModal from "components/support/SupportModal";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { IdentityService } from "services/identity/IdentityService";
import Manage from "components/manage/Manage";
import { AppContextUtil } from "services/appContext/AppContextUtil";
import { GetAppContext, AppContext } from "services/appContext/AppContext";
import { NotificationService } from "services/notification/NotificationService";
import OrderDetails from "components/orders/orderDetails/OrderDetails";
import PublicRoute from "services/routing/PublicRoute";
import PortOut from "components/orders/portCreate/portOut/PortOut";
import PortIn from "components/orders/portCreate/portIn/PortIn";
import AuditList from "components/audits/AuditList";
import AuditDetails from "components/audits/AuditDetails";
import { AdminRoute } from "./services/routing/AdminRoute";
import { PermissionType } from "services/authorization/PermissionType";
import QuerySv from "components/querySV/QuerySv";
import QueryDetails from "components/querySV/QueryDetails";
import Unauthorized from "components/account/Unauthorized";
import Index from "components/Index";
import NotificationList from "components/manage/notifications/NotificationList";
import Reports from "components/reports/Reports";
import VersionChecker from "components/common/VersionChecker";
import WorkQueueList from "components/workQueue/WorkQueueList";
import WorkQueueDetails from "components/workQueue/WorkQueueDetails";
import Signup from "components/account/Signup";
import SignupVerification from "components/account/SignupVerification";
import AdminReports from "components/reports/AdminReports";
import Inventory from "components/networkExplorer/inventory/Inventory";
import TradingPartners from "components/networkExplorer/tradingPartners/TradingPartners";
import CustomerInventory from "components/customerInventory/customerInventory";
import WebhookLogs from "components/webhookLogs/WebhookLogs";
import DisasterRecovery from "components/orders/portCreate/disasterRecovery/DisasterRecovery";

export default function App() {
  const [appContext, setAppContext] = useState(GetAppContext());
  AppContextAccessor.setAppContextSetter(setAppContext);
  AppContextAccessor.setAppContextGetter(() => appContext);
  const appContextWrapper = useMemo(() => ({ appContext, setAppContext }), [
    appContext,
    setAppContext
  ]);
  useEffect(() => {
    if (appContext.localStorageInfo.authenticationInfo.authenticated && appContext.localStorageInfo && appContext.localStorageInfo.user && appContext.localStorageInfo.user.userId !== '') {
      const userId = appContext.localStorageInfo?.user?.userId;
      const userEmail = appContext.localStorageInfo?.user?.email;
      const firstName = appContext.localStorageInfo?.user?.firstName;
      const lastName = appContext.localStorageInfo?.user?.lastName;
      (window as any).initializeParativeIo(userId, userEmail,firstName,lastName,process.env.REACT_APP_PARATIVE_IO_APP_TOKEN);
    }
    else {
      (window as any).destroyParativeIo();
    }
  }, [appContext.localStorageInfo,appContext.localStorageInfo.authenticationInfo.authenticated])
  useEffect(() => {
    AppContextUtil.saveToLocalStorage(appContext.localStorageInfo);
  }, [appContext]);

  useEffect(() => {
    (async () => {
      await IdentityService.checkAndRenewAccessToken();

      setAppContext((appContext) => {
        return { ...appContext, initialized: true };
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (appContext.initialized && appContext.localStorageInfo.authenticationInfo.authenticated) {
        await IdentityService.fetchUserInfo();
        await NotificationService.initalise();
      }
    })();
  }, [appContext.initialized, appContext.localStorageInfo.authenticationInfo.authenticated]);

  return (
    <AppContext.Provider value={appContextWrapper}>
      <AppContext.Consumer>
        {({ appContext }) => (
          <IntlProvider
            locale={appContext.localStorageInfo.language}
            messages={translations[appContext.localStorageInfo.language]}>
            <VersionChecker />
            <HashRouter>
              <Switch>
                <PublicRoute path="/Login">
                  <Login />
                </PublicRoute>
                <PublicRoute path="/Signup">
                  <Signup />
                </PublicRoute>
                <PublicRoute path="/AccountVerification/:id">
                  <SignupVerification />
                </PublicRoute>
                <PublicRoute path="/ForgotPassword">
                  <ForgotPassword />
                </PublicRoute>
                <PublicRoute path="/ResetPassword">
                  <ResetPassword />
                </PublicRoute>
                <AdminRoute path="/NumberExplorer" userPermissions={[PermissionType.Explore]}>
                  <NumberExplorer />
                </AdminRoute>
                <AdminRoute path="/NetworkExplorer" userPermissions={[PermissionType.Network]}>
                  <NetworkExplorer />
                </AdminRoute>
                <AdminRoute path="/Inventory" userPermissions={[PermissionType.Network]}>
                  <Inventory />
                </AdminRoute>
                <AdminRoute path="/pooled" userPermissions={[PermissionType.Network]}>
                  <Inventory />
                </AdminRoute>
                <AdminRoute path="/TradingPartnerUser" userPermissions={[PermissionType.TradingPartners]}>
                  <TradingPartners />
                </AdminRoute>
                <AdminRoute path="/TradingPartnerAdmin" userPermissions={[PermissionType.TradingPartners]}>
                  <TradingPartners/>
                </AdminRoute>
                <AdminRoute path="/EmailNotifications" userPermissions={[PermissionType.Notifications]}>
                  <WebhookLogs/>
                </AdminRoute>
                <AdminRoute path="/UrlNotifications" userPermissions={[PermissionType.Notifications]}>
                  <WebhookLogs/>
                </AdminRoute>
                <AdminRoute
                  path="/Orders/PortIn"
                  spidPermissions={[PermissionType.SOAWrite, PermissionType.PreOrderFree]}>
                  <PortIn />
                </AdminRoute>
                <AdminRoute
                  exact
                  path="/Orders/DisasterRecovery"
                  spidPermissions={[PermissionType.SOAWrite, PermissionType.PreOrderFree]}
                  userPermissions={[PermissionType.DisasterRecovery]}>
                  <DisasterRecovery />
                </AdminRoute>
                <AdminRoute
                  path="/Orders/DisasterRecovery/:id"
                  userPermissions={[PermissionType.DisasterRecovery]}
                  spidPermissions={[PermissionType.SOARead, PermissionType.PreOrderFree, PermissionType.CarrierProfiles]}>
                  <OrderDetails />
                </AdminRoute>
                <AdminRoute path="/Orders/PortOut" spidPermissions={[PermissionType.SOAWrite]}>
                  <PortOut />
                </AdminRoute>
                <AdminRoute
                  path="/Orders"
                  spidPermissions={[PermissionType.SOARead, PermissionType.PreOrderFree]}
                  userPermissions={[PermissionType.CarrierProfiles]}
                  exact>
                  <OrdersList />
                </AdminRoute>              
                <AdminRoute
                  path="/Orders/:id"
                  spidPermissions={[PermissionType.SOARead, PermissionType.PreOrderFree, PermissionType.CarrierProfiles]}
                  userPermissions={[PermissionType.CarrierProfiles]}>
                  <OrderDetails />
                </AdminRoute>
                <AdminRoute path="/WorkQueue" userPermissions={[PermissionType.WorkQueue]} vpopDisabled={true} exact>
                  <WorkQueueList />
                </AdminRoute>
                <AdminRoute path="/CustomerInventory" userPermissions={[PermissionType.CustomerInventory]}>
                  <CustomerInventory/>
                </AdminRoute>
                <AdminRoute path="/WorkQueue/:id" userPermissions={[PermissionType.WorkQueue]} vpopDisabled={true}>
                  <WorkQueueDetails />
                </AdminRoute>
                <AdminRoute path="/QuerySV" spidPermissions={[PermissionType.SOARead]} vpopDisabled={true} exact>
                  <QuerySv />
                </AdminRoute>
                <AdminRoute path="/QuerySV/:id" spidPermissions={[PermissionType.SOARead]} vpopDisabled={true}>
                  <QueryDetails />
                </AdminRoute>
                <AdminRoute path="/Audits" spidPermissions={[PermissionType.SOARead]} vpopDisabled={true} exact>
                  <AuditList />
                </AdminRoute>
                <AdminRoute path="/Audits/:id" spidPermissions={[PermissionType.SOARead]} vpopDisabled={true}>
                  <AuditDetails />
                </AdminRoute>
                <AdminRoute path="/Manage" userPermissions={[PermissionType.CompanyAdmin]}>
                  <Manage />
                </AdminRoute>
                <AdminRoute
                  path="/Reports"
                  userPermissions={[PermissionType.Reports, PermissionType.CompanyAdmin]}
                  vpopDisabled={true}
                  nonSpidDisabled={true}
                  >
                  <Reports />
                </AdminRoute>
                <AdminRoute
                  path="/AdminReports"
                  userPermissions={[PermissionType.Admin]}>
                  <AdminReports />
                </AdminRoute>              
                <AdminRoute path="/Notifications" userPermissions={[PermissionType.Webhooks]}>
                  <NotificationList />
                </AdminRoute>
                <AdminRoute path="/MyProfile">
                  <MyProfile />
                </AdminRoute>
                <AdminRoute path="/Support">
                  <SupportModal />
                </AdminRoute>
                <AdminRoute path="/" exact>
                  <Index />
                </AdminRoute>
                <AdminRoute path="/Unauthorized">
                  <Unauthorized />
                </AdminRoute>
                <Redirect from="*" to="/" />
              </Switch>
            </HashRouter>
          </IntlProvider>
        )}
      </AppContext.Consumer>
    </AppContext.Provider>
  );
}
